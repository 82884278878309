import Swal from 'sweetalert2';

export function showSuccessWithButton({ title, message, footer }) {
  Swal.fire({
    icon: 'success',
    title: title,
    text: message,
    footer: footer ?? '<span>Sportx</span>',
  });
}
export function showErrorWithButton({ title, message, footer }) {
  Swal.fire({
    icon: 'error',
    title: title,
    text: message,
    footer: footer ?? '<span>Sportx</span>',
  });
}
export function showWarningWithButton({ title, message, footer }) {
  Swal.fire({
    icon: 'warning',
    title: title,
    text: message,
    footer: footer ?? '<span>Sportx</span>',
  });
}
export function showSuccess({ title, message }) {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: title,
    text: message,
    showConfirmButton: false,
    timer: 2500,
  });
}

export function showError({ title, message }) {
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: title,
    text: message,
    showConfirmButton: true,
  });
}

export function showWarning({ title, message }) {
  Swal.fire({
    position: 'top-end',
    icon: 'warning',
    title: title,
    text: message,
    showConfirmButton: true,
  });
}
